
import Guardian from '@/types/guardian'
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      guardian: {} as Guardian
    }
  },
  methods: {
    async register () {
      const token = await this.$recaptcha('register')
      this.$api.setRecaptchaToken(token)
      const response = await this.$api.register(this.guardian)
      if (response.guardian) {
        this.$store.commit('success', { success: 'Registered. Check your E-mail for a link to verify you account.', preserve: true })
        this.$router.replace({ name: 'login' })
      }
    },
    passwordMismatch (event: Event) {
      const target = event.target as HTMLInputElement
      if (target.value !== this.guardian.password) {
        target.setCustomValidity('Passwords do not match.')
      } else {
        target.setCustomValidity('')
      }
    }
  }
})
