<template>
<div class="container mt-3">
  <h4 class="mb-3">Register</h4>
  <form @submit.prevent="register()">
    <div class="mb-3">
      <label class="form-label" for="emailAddress">E-mail address</label>
      <input class="form-control" type="email" name="emailAddress" id="emailAddress" v-model="guardian.emailAddress" required>
    </div>
    <div class="row">
      <div class="col-lg">
        <div class="mb-3">
          <label class="form-label" for="password">Password</label>
          <input class="form-control" type="password" name="password" id="password" v-model="guardian.password" required>
        </div>
      </div>
      <div class="col-lg">
        <div class="mb-3">
          <label class="form-label" for="password2">Re-type password</label>
          <input class="form-control" type="password" name="password2" id="password2" required @change="passwordMismatch">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg">
        <div class="mb-3">
          <label class="form-label" for="firstName">First Name</label>
          <input class="form-control" type="text" name="firstName" id="firstName" v-model="guardian.firstName" required>
        </div>
      </div>
      <div class="col-lg">
        <div class="mb-3">
          <label class="form-label" for="lastName">Last Name</label>
          <input class="form-control" type="text" name="lastName" id="lastName" v-model="guardian.lastName" required>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <label class="form-label" for="phoneNumber">Phone number</label>
      <input class="form-control" type="tel" name="phoneNumber" id="phoneNumber" v-model="guardian.phoneNumber" required>
    </div>
    <div class="mb-3">
      <label class="form-label" for="addressStreet">Street Address</label>
      <input class="form-control" type="text" name="addressStreet" id="addressStreet" v-model="guardian.addressStreet" required>
    </div>
    <div class="row">
      <div class="col-lg">
        <div class="mb-3">
          <label class="form-label" for="addressCity">City</label>
          <input class="form-control" type="text" name="addressCity" id="addressCity" v-model="guardian.addressCity" required>
        </div>
      </div>
      <div class="col-lg">
        <div class="mb-3">
          <label class="form-label" for="addressState">State</label>
          <select class="form-select" name="addressState" id="addressState" v-model="guardian.addressState" required>
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </select>
        </div>
      </div>
      <div class="col-lg">
        <div class="mb-3">
          <label class="form-label" for="addressZip">ZIP code</label>
          <input class="form-control" type="text" name="addressZip" id="addressZip" v-model="guardian.addressZip" required>
        </div>
      </div>
    </div>
    <button class="btn btn-primary" type="submit">Continue</button>
  </form>
</div>
</template>

<script lang="ts">
import Guardian from '@/types/guardian'
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      guardian: {} as Guardian
    }
  },
  methods: {
    async register () {
      const token = await this.$recaptcha('register')
      this.$api.setRecaptchaToken(token)
      const response = await this.$api.register(this.guardian)
      if (response.guardian) {
        this.$store.commit('success', { success: 'Registered. Check your E-mail for a link to verify you account.', preserve: true })
        this.$router.replace({ name: 'login' })
      }
    },
    passwordMismatch (event: Event) {
      const target = event.target as HTMLInputElement
      if (target.value !== this.guardian.password) {
        target.setCustomValidity('Passwords do not match.')
      } else {
        target.setCustomValidity('')
      }
    }
  }
})
</script>
